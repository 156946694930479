<template>
  <div>
    <b-card header-tag="header">
      <div class="d-flex align-items-center mb-10">
        <div class="symbol symbol-40 mr-5 symbol-light-primary">
          <span class="symbol-label"
            ><span class="svg-icon svg-icon-lg svg-icon-primary">
              <i class="las la-file-alt"></i> </span
          ></span>
        </div>
        <div class="d-flex flex-column font-weight-bold">
          <a class="text-dark text-hover-primary mb-1 font-size-lg">Product Details </a>
          <span class="text-muted">Name, type,description etc...</span>
        </div>
      </div>
      <template v-slot:header>
        <product-form-nav
          link="basic"
          :productDetails="productDetails"
          :productId="id"
        ></product-form-nav>
      </template>

      <form class="form" novalidate="novalidate" id="product_basic">
        <b-card-text>
          <b-row class="align-items-center">
            <b-col lg="4" md="4">
              <div>
                <b-form-group class="fw-600" label="Select product type">
                  <b-form-select
                    v-model="form.product_type"
                    name="type"
                    :options="productTypeList"
                  >
                  </b-form-select>
                </b-form-group>
              </div>
            </b-col>
            <b-col lg="4" md="4">
              <b-row class="align-items-center">
                <b-col md="12" class="fw-600">Does the product have lens? </b-col>
                <b-col lg="5" md="12" class="pt-2 v-application">
                  <v-radio-group v-model="form.prescription_status" row>
                    <v-radio label="Yes" :value="1" class="mr-8"></v-radio>
                    <v-radio label="No" :value="0" class="m-0 p-0"></v-radio>
                  </v-radio-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="4" md="4">
              <b-row class="align-items-center">
                <b-col md="12" class="fw-600">Does the product have varient? </b-col>
                <b-col lg="5" md="12" class="pt-2 v-application">
                  <v-radio-group v-model="form.varient" row>
                    <v-radio label="Yes" :value="1" class="mr-8"></v-radio>
                    <v-radio label="No" :value="0" class="m-0 p-0"></v-radio>
                  </v-radio-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="4" md="4">
              <b-row class="align-items-center">
                <b-col md="12" class="fw-600">Is this a suglass? </b-col>
                <b-col lg="5" md="12" class="pt-2 v-application">
                  <v-radio-group v-model="form.is_sunglass" row>
                    <v-radio label="Yes" :value="1" class="mr-8"></v-radio>
                    <v-radio label="No" :value="0" class="m-0 p-0"></v-radio>
                  </v-radio-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="align-items-center mt-5">
            <b-col md="4">
              <b-form-group label-for="input-1">
                <label class="fw-600"
                  >Product Name
                  <sup class="text-danger font-weight-boldest">*</sup></label
                >
                <b-form-input
                  v-model="form.name"
                  type="text"
                  name="name"
                  class="form-control"
                  placeholder="EG: Red Parrot"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="input-1">
                <label class="fw-600"
                  >Product Slug
                  <sup class="text-danger font-weight-boldest">*</sup></label
                >
                <b-form-input
                  v-model="form.page_key"
                  type="text"
                  name="slug"
                  class="form-control"
                  placeholder="EG: Red Parrot"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="3" md="6">
              <b-form-group>
                <label class="fw-600">Product Model</label>
                <b-form-input
                  v-model="form.model"
                  name="model"
                  placeholder="EG:B6SGB093"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-text>
        <b-row class="">
          <b-col md="12">
            <b-form-group>
              <label class="fw-600">Product Description</label>
              <b-form-textarea
                id="textarea"
                v-model="form.description"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="">
          <b-col lg="3" md="6">
            <b-form-group>
              <label class="fw-600">Material</label>

              <b-form-select
                v-model="form.material"
                :options="framematerial"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="6">
            <b-form-group>
              <label class="fw-600">Frame Type</label>
              <b-form-select
                v-model="form.frame_type"
                :options="frametype"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="6">
            <b-form-group>
              <label class="fw-600">Completion Status</label>
              <b-form-select
                v-model="form.complete_status"
                :options="completionStatus"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="6">
            <b-form-group>
              <label class="fw-600">Active Status</label>
              <b-form-select v-model="form.status" :options="statusList"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex align-items-center mb-10">
          <div class="symbol symbol-40 mr-5 symbol-light-primary">
            <span class="symbol-label"
              ><span class="svg-icon svg-icon-lg svg-icon-primary">
                <i class="las la-hashtag"></i> </span
            ></span>
          </div>
          <div class="d-flex flex-column font-weight-bold">
            <a class="text-dark text-hover-primary mb-1 font-size-lg">SEO</a>
            <span class="text-muted">Meta tags and keyword</span>
          </div>
        </div>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <label class="fw-600">Meta Tag Title</label>
              <b-form-input
                v-model="form.meta_title"
                name="metaTitle"
                type="text"
                class="form-control"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group label="Meta Tag Description" class="fw-600">
              <b-form-textarea
                v-model="form.meta_description"
                name="metaDescription"
                placeholder="Enter Meta Tag Description"
                rows="3"
                max-rows="6"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Meta Tag Keywords" class="fw-600">
              <b-form-textarea
                v-model="form.meta_keyword"
                placeholder="Enter Meta Tag Keywords"
                rows="3"
                max-rows="6"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <b-form-group
              description="Enter tags separated by space, comma or semicolon"
              class="fw-600"
            >
              <label class="fw-600"
                >Product Tags<sup class="text-danger font-weight-boldest">*</sup></label
              >
              <b-form-input v-model="form.tags"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group label-for="input-1">
              <label>Seo Title</label>
              <b-form-input
                v-model="form.seo_title"
                type="text"
                name="seo-title"
                class="form-control"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <!-- <b-form-group class="fw-600">
              <label class="fw-600">Seo Content</label>
              <b-form-textarea
                v-model="form.seo_content"
                placeholder=""
                rows="3"
                max-rows="6"
              >
              </b-form-textarea>
            </b-form-group> -->
            <b-form-group label-for="input-1">
          <label>Seo Content </label>
          <Editor
            v-model="form.seo_content"
            name="seocontent"
            editorStyle="height: 320px"
          />
        </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <button class="btn btn-primary" ref="ha_submit">
              <i class="las la-save"></i> Save and Continue
            </button>
          </b-col>
        </b-row>
      </form>
    </b-card>
  </div>
</template>

<script>
import ProductFormNav from "./components/ProductFormNav.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import Editor from "primevue/editor";


import ProductService from "@/core/services/api/product/product";
import MaterialService from "@/core/services/api/product/material";
import FrameTypeService from "@/core/services/api/product/frameType";
export default {
  components: {
    ProductFormNav,
    Editor
  },
  data() {
    return {
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
      productTypeList: [
        { value: "frame", text: "Frame" },
        { value: "accessory", text: "Accessory" },
      ],
      completionStatus: [
        { value: "not", text: "InComplete" },
        { value: "completed", text: "Complete" },
      ],
      statusList: [
        { value: "active", text: "Active" },
        { value: "inactive", text: "Inactive" },
      ],
      form: {
        name: "",
        page_key: "",
        model: "",
        description: "",
        product_type: "frame",
        varient: 1,
        is_sunglass: 0,
        meta_title: "",
        meta_description: "",
        meta_keyword: "",
        seo_content: "",
        seo_title: "",
        tags: "",
        complete_status: "not",
        status: "active",
        material: 0,
        frame_type: 0,
        prescription_status: 1,
      },
      frametype: [],
      framematerial: [],
      productDetails: {},
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Products",
        route: "/products",
      },
      {
        title: "Manage Product : Basic",
      },
    ]);
    const signin_form = KTUtil.getById("product_basic");
    this.fv = formValidation(signin_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Product name is required",
            },
            stringLength: {
              min: 2,
              message: "The Product name must be minimum 2 characters",
            },
          },
        },
        slug: {
          validators: {
            notEmpty: {
              message: "Product slug is required",
            },
            stringLength: {
              min: 2,
              message: "The Product name must be minimum 2 characters",
            },
          },
        },
        model: {
          validators: {
            notEmpty: {
              message: "Product model is required",
            },
            stringLength: {
              min: 2,
              message: "The Product model must be minimum 3 characters",
            },
          },
        },
        type: {
          validators: {
            notEmpty: {
              message: "Product type is required",
            },
          },
        },
        metaTitle: {
          validators: {
            notEmpty: {
              message: "Meta title is required",
            },
            stringLength: {
              min: 2,
              message: "The meta title must be minimum 2 characters",
            },
          },
        },
        metaDescription: {
          validators: {
            stringLength: {
              max: 255,
              message: "The meta description must be less than 256 characters",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
    this.getMaterialList();
    this.getFrameTypeList();
    this.fv.on("core.form.valid", () => {
      // set spinner to submit button
      const submitButton = this.$refs["ha_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right", "disabled");

      if (this.id == null) {
        this.createProduct(function () {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right",
            "disabled"
          );
        });
      } else {
        this.updateProduct(function () {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right",
            "disabled"
          );
        });
      }
    });
    if (this.id != null) {
      this.getProductById();
    }
  },
  methods: {
    async getMaterialList() {
      var response = await MaterialService.getMaterialList();
      this.framematerial.push({
        value: 0,
        text: "Please select a frame material",
      });
      response.map((e) => {
        this.framematerial.push({
          value: e.id,
          text: e.name,
        });
      });
    },
    async getFrameTypeList() {
      var response = await FrameTypeService.getFrameTypeList();
      this.frametype.push({
        value: 0,
        text: "Please select a frame type",
      });
      response.map((e) => {
        this.frametype.push({
          value: e.id,
          text: e.name,
        });
      });
    },
    async getProductById() {
      var response = await ProductService.getProductById(this.id);
      this.productDetails = response;
      this.form = {
        name: response.name,
        page_key: response.page_key,
        model: response.model,
        description: response.description,
        product_type: response.product_type,
        varient: response.varient,
        is_sunglass: response.is_sunglass,
        meta_title: response.meta_title,
        meta_description: response.meta_description,
        meta_keyword: response.meta_keyword,
        tags: response.tags,
        complete_status: response.complete_status,
        status: response.status,
        material: response.material,
        frame_type: response.frame_type,
        prescription_status: response.prescription_status,
        seo_content: response.seo_content,
        seo_title: response.seo_title,
      };
    },
    createProduct(callback) {
      var vm = this;
      ProductService.createProduct(vm.form)
        .then(function (response) {
          vm.$router.push({ path: "/product/attribute/" + response.id });
        })
        .catch(function (error) {
          callback();
        });
    },
    updateProduct(callback) {
      var vm = this;
      ProductService.updateProduct(vm.id, vm.form)
        .then(function (response) {
          vm.$router.push({ name: "product-form-attribute", param: { id: vm.id } });
        })
        .catch(function (error) {
          callback();
        });
    },
  },
};
</script>

<style></style>
